input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: #fff;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  border-radius: 5px;

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    background: #3071a9;
    border-radius: 5px;
    border: 0px solid #010101;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    position: relative;
    top: -5;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 50%;
    border: 2px solid #010101;
    cursor: pointer;
  }
}

.range {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  font-size: smaller;

  label {
    flex: 0 0 20%;
    text-align: left;
    color: #333;
  }

  input {
    background-color: red;
    flex: 1 0 70%;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 1em;
    text-align: center;
    margin: 0 5px;
  }

  .range-value {
    flex: 0 0 10%;
    text-align: left;
    color: #333;
  }
};


