.segmented {
  margin: 10px 0;

  input {
    margin-right: 5px;
  }

  label {
    margin-right: 15px;
  }
}
