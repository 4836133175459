body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 20px;
}

.outer {
  max-width: 525px;
  margin: 0 auto;
}

.tree-container {
  width: 100%;
  aspect-ratio: 1;
  margin-top: 20px;
  border: 1px #ddd solid;
}

.tree-drawing {
  width: 100%;
  height: 100%;  
}

