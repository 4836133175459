body {
  padding: 20px;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.outer {
  max-width: 525px;
  margin: 0 auto;
}

.tree-container {
  width: 100%;
  aspect-ratio: 1;
  border: 1px solid #ddd;
  margin-top: 20px;
}

.tree-drawing {
  width: 100%;
  height: 100%;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  opacity: .7;
  -webkit-transition: all .2s;
  background: #fff;
  border-radius: 5px;
  outline: none;
  transition: opacity .2s;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  background: #3071a9;
  border: 0 solid #010101;
  border-radius: 5px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: rgba(255, 255, 255, .9);
  border: 2px solid #010101;
  border-radius: 50%;
  position: relative;
  top: -5px;
}

.range {
  width: 100%;
  flex-direction: row;
  padding: 5px 0;
  font-size: smaller;
  display: flex;
}

.range label {
  text-align: left;
  color: #333;
  flex: 0 0 20%;
}

.range input {
  width: 100%;
  text-align: center;
  background-color: red;
  border: none;
  outline: none;
  flex: 1 0 70%;
  margin: 0 5px;
  padding: 0;
  font-size: 1em;
}

.range .range-value {
  text-align: left;
  color: #333;
  flex: 0 0 10%;
}

.segmented {
  margin: 10px 0;
}

.segmented input {
  margin-right: 5px;
}

.segmented label {
  margin-right: 15px;
}

/*# sourceMappingURL=index.267cc8a8.css.map */
